import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

function ShopsPage() {
  return (
    <Layout>
      <SEO
        keywords={[
          `Заказать сайт Пыть-Ях`,
          `Заказать сайт Пушкин`,
          `Разработка сайтов Пыть-Ях`,
          `Разработка сайтов Пушкин`,
          `Разработка интернет-магазинов`,
          `Интернет-приложения под ключ`,
        ]}
        title='Сайты'
      />

      <section className='flex flex-col items-center  px-6'>
        <h2 className='text-2xl text-center uppercase m-6 p-4 tracking-wider'>
          Разработка и поддержка интернет-приложений и сайтов
        </h2>
        <p className='text-lg text-center  m-6 p-4 bg-blue-200'>
          Это новое для нас направление, но мы сразу стали использовать
          современнейшие на мировом рынке технологии.
        </p>
        <p className='text-lg bg-teal-200 text-center m-6 p-4'>
          Основной упор делается на оптимизацию и скорость. Пример - этот сайт.
        </p>
        <p className='text text-red-600 text-center m-6 p-4'>
          АнтиВИРУСная акция - интернет магазин в связке с 1С:УНФ. Запуск за
          один рабочий день.
        </p>
      </section>
    </Layout>
  );
}

export default ShopsPage;
